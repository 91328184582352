/* 路由过渡效果 */
.fade-enter {
	opacity: 0;
	transform: translateX(-30px);
}

.fade-enter-active,
.fade-exit-active {
	opacity: 1;
	transition: all 500ms ease-out;
	transform: translateX(0);
}
.fade-exit {
	opacity: 0;
	transform: translateX(30px);
}
$borderColor: #d8d8d8;
.app-sider {
	border-right: 1px solid $borderColor;
	overflow-y: auto;
	overflow-x: hidden;
}
.ant-menu-inline {
	border: none;
}
.logo {
	height: 32px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px;
	text-align: center;

    line-height: 32px;
}
.top-header {
	background: #fff;
	border-bottom: 1px solid $borderColor;
	padding: 0 15px;
	position: relative;
	.top-header-inner {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		.header-title {
			flex: 1;
			padding-left: 15px;
			font-size: 20px;
		}
		.header-right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 320px;
			> div {
				margin-left: 20px;
			}
		}
	}
	.drop-list {
		li {
			padding: 10px 15px;
		}
	}
}

.shadow-radius {
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	padding: 15px;
	background-color: #fff !important;
	min-height: 85vh;
}

.public-title {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid rgb(217, 217, 217);
	margin-bottom: 10px;
}

.link-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: inline;
	margin: 0;
	padding: 0;
	color: #1890ff;
}

.link-button:hover,
.link-button:focus {
	text-decoration: none;
}
.tags {
	display: flex;
	margin-bottom: 4px;
	.tags-scroll {
		position: relative;
		width: 100%;
		height: 31px;
	
	}
}
.tags-list {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	padding: 0;
	transition: all 0.2s ease-in-out 0s;
	width: 100%;
	li {
		list-style: none;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $borderColor;
		padding: 4px 22px;
		position: relative;
		margin-right: 4px;
		word-break: keep-all;
		cursor: pointer;
		&:before {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			background: #b1b1b1;
			border-radius: 50%;
			left: 8px;
			top: 12px;
			position: absolute;
		}
		&:first-child {
			em,
			&:before {
				display: none;
			}
		}
		em {
			font-style: normal;
			font-size: 16px;
			margin-left: 4px;
			width: 14px;
			height: 14px;
			position: absolute;
			right: 4px;
			top: 2px;
		}
		.hide {
			display: none;
		}
	}
	.isActive {
		color: #fff;
		background: linear-gradient(to right, #2187e8, #1890ff);
		border-color: #1890ff;
		&:before {
			background: #fff;
		}
	}
}

.search-form {
	padding: 15px;
	.ant-form-item {
		display: flex;
	}
	.ant-form-item-control-wrapper {
		flex: 1;
	}
}
.serarch-btns {
	display: flex;
	.ant-form-item {
		margin-right: 10px;
	}
}
.breadCrumb {
	padding: 15px;
	padding-bottom: 0;
}

.list {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	li {
		width: calc(100% / 8);
		height: 100px;
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		cursor: pointer;
		i {
			font-size: 28px;
			margin-bottom: 5px;
		}
		&:hover {
			background: #e4e4e4;
			transition: all 0.15s ease-in-out 0s;
			i {
				transform: scale(1.5);
				transition: all 0.15s ease-in-out 0s;
			}
		}
	}
}

.form-item-margin{
	margin-top: 20px !important;
}

.table-layout{
	margin-top: 40px ;
}

.ant-modal-header{
	border-bottom:0px !important
}

.dashboard-card-shadow{
	box-shadow: 0 2px 4px rgba(31,30,47,0.1);
}
.dashboard-card-layout{
	box-sizing: border-box;
	background: #ffffff;
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	.h3{
		
		color: #1c2d41;
		font-weight: 600;
		font-size: 28px;
	}
}